import React, { Component } from 'react';
import { HashRouter, Route, Switch, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';

import trLocale from 'moment/locale/es';
import moment from 'moment';
moment.locale('es', trLocale);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const Home = React.lazy( () => import('./components/pages/home/Home') );
const Evento = React.lazy( () => import('./components/pages/evento/Evento') );
const EventoInvitados = React.lazy( () => import('./components/pages/evento/invitados/Invitados') );
const MenuEvento = React.lazy( () => import('./components/pages/evento/menu/Menu') );

class App extends Component {

  render() {
    return (
      <>
        <ToastContainer pauseOnFocusLoss={false} />
        {
          process.env.NODE_ENV == 'development' ?
          (
            <HashRouter>
                <React.Suspense fallback={loading}>
                  <Switch>
                    <Route exact path="/:slug/menu" name="Menu del evento" render={props => <MenuEvento {...props}/>} />
                    <Route exact path="/:slug/:uuid" name="Invitados" render={props => <EventoInvitados {...props}/>} />
                    <Route path="/:slug" name="Evento" render={props => <Evento {...props}/>} />
                    <Route path="/" name="Home" render={props => <Home {...props}/>} />

                  </Switch>
                </React.Suspense>
            </HashRouter>
          )
          :
          (
            <BrowserRouter>
                <React.Suspense fallback={loading}>
                  <Switch>
                    <Route exact path="/:slug/menu" name="Menu del evento" render={props => <MenuEvento {...props}/>} />
                    <Route exact path="/:slug/:uuid" name="Invitados" render={props => <EventoInvitados {...props}/>} />
                    <Route path="/:slug" name="Evento" render={props => <Evento {...props}/>} />
                    <Route path="/" name="Home" render={props => <Home {...props}/>} />
                  </Switch>
                </React.Suspense>
            </BrowserRouter>
          )
        }
      </>
    );
  }
}

export default App;
